import { type BenjiConfig } from "@vzmi/benji";
import { type Size } from "./client";

declare global {
  interface Window {
    benji: any; // TODO type?
  }
}

const BENJI_SITE_MAPPING: Record<string, string> = {
  autos: "yauto",
  health: "yhealth",
  local: "ylocal",
  shopping: "yshopping",
  tech: "ytech",
};

type BenjiAdPositions = BenjiConfig["positions"];
export type BenjiAdPositionConfig = BenjiAdPositions[string];

export interface BenjiRenderFailedEvent {
  data: {
    id: string;
  };
}

export interface BenjiRenderSucceededEvent {
  data: {
    id: string;
    size: [number, number] | null;
  };
}

/** [width, height] */
export type BenjiSize = [number, number];

export type Device = "dt" | "mw";

export const buildAdPath = ({
  device,
  location,
  placement = "ros",
  site = "${SITE}",
}: {
  device: Device;
  location: string;
  placement?: string;
  site?: string;
}) => {
  const benjiSite = BENJI_SITE_MAPPING[site] || site;
  return `/22888152279/\${REGION}/${benjiSite}/${placement}/${device}/\${REGION}_${benjiSite}_${placement}_${device}_${location}`;
};

export const buildBenjiAdConfig = ({
  customSizeConfig,
  device,
  id,
  kvs,
  location,
  locationNumber,
  placement,
  region,
  site,
  sizes,
  stackGroup,
}: {
  customSizeConfig?: Record<string, boolean>;
  device: Device;
  id: string;
  kvs?: Record<string, string>;
  location: string;
  locationNumber: number;
  placement: string;
  region: string;
  site?: string;
  sizes: Size[];
  stackGroup?: string;
}): BenjiAdPositionConfig => ({
  customSizeConfig,
  id,
  kvs: {
    loc: locationNumber === 1 ? location : `${location}_${locationNumber}`,
    ...kvs,
  },
  path: buildAdPath({ device, location, placement, site }),
  region,
  size: sizes.map(toBenjiSize),
  stackGroup,
});

/**
 * Destroys the given ads, by ID
 *
 * NOTE: Benji's `destroy` function has two signatures with pretty different behaviors:
 * - `destroy(input: string)` – destroys an entire region (e.g. "index") of ads, not a single ad by "<id>"
 * - `destroy(inputs: string[])` - destroys specific ads by "<id>" or by "<region>:<id>"
 */
export const destroyAds = (ids: string[]): void => {
  window.benji.destroy(ids);
};

export const fromBenjiSize = ([width, height]: BenjiSize): Size => ({
  height,
  width,
});

export const renderAds = (configs: BenjiAdPositionConfig[]) => {
  window.benji.render(
    configs.reduce(
      (existingPositions, config) => ({
        ...existingPositions,
        [config.id]: config,
      }),
      {} as BenjiAdPositions,
    ),
  );
};

export const toBenjiSize = ({ height, width }: Size): BenjiSize => [
  width,
  height,
];
