"use client";

import { useMatchesMediaQuery } from "@yahoo-commerce/util";
import classNames from "classnames";
import {
  forwardRef,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
  type FC,
  type ReactNode,
} from "react";
import { useIntl } from "react-intl";
import { useAd } from "./AdContext";
import { buildBenjiAdConfig, type Device } from "./benji";
import {
  buildScreenWidthMediaQuery,
  type ScreenWidth,
  type Size,
} from "./client";

export type AdDisplayMode = "collapsed" | "hidden" | "placeholder" | "visible";
export type AdLoadingMode = "eager" | "lazy";
export type AdPlacement = "main" | "ros";

export interface AdWrapperProps {
  children: ReactNode;
  displayMode: AdDisplayMode;
  isPremium: boolean;
  locationNumber: number;
}

export interface BaseAdProps {
  className?: string;
  loading?: AdLoadingMode;
  location: string;
  locationNumber?: number;
  onResize?: ResizeEventHandler;
  pageRegion: string;
  placement?: string;
  /**
   * Determines if the ad container can resize itself:
   * - shrinking down to the served ad's size, if smaller
   * - collapsing if an ad fails to serve
   *
   * WARNING: when enabled, this can lead to layout shifts if not used carefully
   */
  resizable: boolean;
  site: string;
}

export interface ScreenSpecificAdProps {
  customSizeConfig?: Record<string, boolean>;
  device: Device;
  placeholder: Size;
  screenWidth: ScreenWidth;
  sizes: Size[];
  wrapper?: FC<AdWrapperProps>;
}

export interface AdProps extends BaseAdProps, ScreenSpecificAdProps {
  kvs?: Record<string, string>;
}

interface ResizeEventHandler {
  (size: Size, isPremium: boolean): void;
}

const DefaultAdWrapper: FC<AdWrapperProps> = ({ children }) => <>{children}</>;

/**
 * Uses React's `useId` to generate a stable identifier for an element,
 * removing special characters that Benji doesn't support and that CSS doesn't
 * play nicely with, namely `:`
 */
const useSanitizedId = () => {
  const reactId = useId();

  return reactId.replace(/:/g, "");
};

export const Ad: FC<AdProps> = ({
  className,
  customSizeConfig,
  device,
  kvs,
  loading = "eager",
  location,
  locationNumber = 1,
  onResize,
  pageRegion,
  placement = "ros",
  resizable,
  screenWidth,
  site,
  sizes,
  wrapper: Wrapper = DefaultAdWrapper,
}) => {
  const mediaQuery = buildScreenWidthMediaQuery(screenWidth);

  const adId = useSanitizedId();
  const containerId = useSanitizedId();
  const elementRef = useRef<HTMLDivElement | null>(null);
  const placeholderRef = useRef<HTMLDivElement | null>(null);
  const visible = useMatchesMediaQuery(mediaQuery);
  const [enabled, setEnabled] = useState(loading === "eager");
  const [renderedAd, setRenderedAd] = useState<Size | null>(null);

  const config = useMemo(
    () =>
      buildBenjiAdConfig({
        customSizeConfig,
        device,
        id: adId,
        kvs,
        location,
        locationNumber,
        placement,
        region: pageRegion,
        site,
        sizes,
      }),
    [
      adId,
      customSizeConfig,
      device,
      kvs,
      location,
      locationNumber,
      pageRegion,
      placement,
      site,
      sizes,
    ],
  );

  const ad = useAd({
    config,
    enabled: visible && enabled,
  });
  let displayMode: AdDisplayMode;
  if (ad.status === "rendered") {
    displayMode = "visible";
  } else if (ad.status === "error") {
    displayMode = resizable ? "collapsed" : "hidden";
  } else {
    displayMode = "placeholder";
  }

  const containerSelector = `#${containerId}`;
  const responsiveStyles = [
    `${containerSelector} { display: none; }`,
    displayMode !== "collapsed" &&
      `@media ${mediaQuery} {
        ${containerSelector} { display: flex; }
        iframe { max-width: 100vw; }
      }`,
  ]
    .filter(Boolean)
    .join("\n");

  useEffect(() => {
    // TODO: use shared hook
    let intersectionObserver: IntersectionObserver | undefined;
    if (loading === "lazy" && placeholderRef.current) {
      intersectionObserver = new IntersectionObserver(
        ([entry]) => {
          if (
            entry.isIntersecting || // visible in the viewport
            entry.boundingClientRect.top < 0 // above the current viewport
          ) {
            // prevent duplicate callbacks
            intersectionObserver?.unobserve(entry.target);
            setEnabled(true);
          }
        },
        { rootMargin: "600px 0px" },
      );

      intersectionObserver.observe(placeholderRef.current);
    }

    return () => {
      intersectionObserver?.disconnect();
    };
  }, [placeholderRef, loading]);

  useEffect(() => {
    if (ad.size) {
      const renderedAd = sizes.find(
        (size) =>
          ad.size?.width === size.width && ad.size?.height === size.height,
      );
      setRenderedAd(renderedAd ?? null);
    }
  }, [ad.size, sizes]);

  useEffect(() => {
    if (renderedAd && onResize) {
      onResize(renderedAd, renderedAd?.isPremium ?? false);
    }
  }, [renderedAd, onResize, sizes]);

  return (
    <Wrapper
      displayMode={displayMode}
      isPremium={renderedAd?.isPremium ?? false}
      locationNumber={locationNumber}
    >
      <div
        className={classNames(className, displayMode === "hidden" && "hidden")}
        id={containerId}
        style={{
          ...((resizable && ["visible", "placeholder"].includes(displayMode)) ||
          renderedAd?.isPremium ||
          ad.status === "error"
            ? {}
            : {
                height: Math.max(...sizes.map((size) => size.height)),
                width: Math.max(...sizes.map((size) => size.width)),
              }),
        }}
      >
        <div
          className={displayMode === "placeholder" ? "hidden" : "flex"}
          id={adId}
          ref={elementRef}
        />
        {displayMode === "placeholder" && (
          <Placeholder size={sizes[0]} ref={placeholderRef} />
        )}
        <style type="text/css">{responsiveStyles}</style>
      </div>
    </Wrapper>
  );
};

interface PlaceholderProps {
  size: Size;
}

const Placeholder = forwardRef<HTMLDivElement, PlaceholderProps>(
  ({ size }, ref) => {
    const intl = useIntl();

    return (
      <div
        className="flex size-full items-center justify-center bg-marshmallow text-center leading-3"
        ref={ref}
        style={size}
      >
        {intl.formatMessage({ id: "monetization.AD_PLACEHOLDER" })}
      </div>
    );
  },
);
